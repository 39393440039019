<template>
  <sign-page
    title-text="党费缴纳"
    :request="request"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    @dataFileUpdate="dataFileUpdate"
    :title-menus="[{key: 'add', label: '新增'}]"
    table-size="large"
    ref="dfjnpage"
    :tableActions="tableActions"
    :init-add-form-data="initAddFormData"
    :form-parms-update="formParmsUpdate"
    :tableActionsFixed="true"
    :need-data-file="true">
    <template v-slot:title>
      <div class="title-s">
        <div class="label">年份:</div>
        <div class="choose">
          <fm-input-new
            style="width: 10rem;"
            absolute
            @change="nyChange"
            v-model="searchNy"
            placeholder="请输入"
        ></fm-input-new>
        </div>
        <div class="label" style="margin-left: 3rem;">上下半年:</div>
        <div class="choose">
          <fm-select absolute filterable :clearable="true" v-model="searchSx" @change="nyChange">
            <fm-option v-for="item in [{key:'上半年', label: '上半年'}, {key:'下半年', label: '下半年'}]" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </div>
      </div>
    </template>
  </sign-page>
</template>

<script>
import {
  workerCertificateRequest as request
} from '../../api'

import {
  dateOperating
} from '@/fmlib'

import {
  loadData
} from '../../auth_lib'

export default {
  created () {
    this.$store.dispatch('loadOrgList')
  },
  computed: {
    tableActions () {
      let data = [{
        key: 'data_file',
        label: '附件'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: this.$store.getters.orgList,
          label: '党组织',
          key: 'workerId',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '年份',
          key: 'xlmc',
          check: {
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'select',
          selectDatas: [{key:'上半年', label: '上半年'}, {key:'下半年', label: '下半年'}],
          label: '上下半年',
          key: 'practiceAddress'
        },
        {
          type: 'input',
          label: '党费',
          key: 'pro',
          check: {
            rules: {
              type: 'number'
            }
          }
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '党组织',
          sort: true,
          field: 'orgName'
        },
        {
          title: '年份',
          sort: true,
          field: 'xlmc',
          render: (h, rowData) => {
            let ny = rowData && rowData.xlmc ? rowData.xlmc.slice(0, 4) : null
            return h('div', ny ? ny : '-')
          }
        },
        {
          title: '上下半年',
          sort: true,
          field: 'practiceAddress'
        },
        {
          title: '党费',
          sort: true,
          field: 'pro'
        }]
        return data
      }
    }
  },
  methods: {
    nyChange () {
      let datas = this.allData
      if (this.searchNy) {
        datas = this.allData.filter(v => v.xlmc && v.xlmc === this.searchNy)
      }
      if (this.searchSx) {
        datas = datas.filter(v => v.practiceAddress && v.practiceAddress === this.searchSx)
      }
      this.$refs.dfjnpage.dataList = datas
    },
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'}),
        fileIds: parm ? parm.join(',') : ''
      })
      pageVm.loadData()
    },
    initAddFormData () {
      return {type: 'dfjn'}
    },
    async loadData () {
      await this.$store.dispatch('loadOrgList')
      let parm = {
        type: 'dfjn'
      }
      this.allData = await loadData(request, this, parm)
      this.allData.forEach(v => {
        v.orgName = (this.$store.getters.orgList.find(v1 => v1.key === v.workerId) || {label: ''}).label
      })
      let datas = this.allData
      if (this.searchNy) {
        let ny = dateOperating.computeDay({days: 0, date: this.searchNy, format: 'yy-mm-dd hh:mm:ss'}).slice(0, 7)
        datas = this.allData.filter(v => v.xlmc && v.xlmc.startsWith(ny))
      }
      return datas
    }
  },
  data () {
    return {
      allData: [],
      searchNy: null,
      searchSx: null,
      choose: null,
      loading: false,
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>

<style lang="less" scope>
.title-s {
  display: flex;
  align-items: center;
  margin-left: 3rem;
  .label {
    margin-right: 1rem;
  }
}
</style>